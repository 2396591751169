.bg-pattern{
    background-color: #0682C9;
}

.fill-pattern {
    fill: #0682C9;
}

.stroke-pattern {
    stroke: #0682C9;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #0682C9;
}

.bg-opaque {
    background-color: rgb(13, 13, 13, 0.13);
}

.bg-opaque:hover {
    background-color: black;
}

.bg-pattern-gradient {
    background: linear-gradient(to bottom, #0682C9 45%, rgb(249, 250, 251) 99%);
}

.bg-buttom-gradient {
    background: linear-gradient(to right, #FFCF00 32.08%, #FFCF00 85.67%);
}

.text-gradient{
    background: linear-gradient(to right, #FFCF00 32.08%, #FFCF00 85.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}